// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

// Ag-grid core css file
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

router-outlet {
  display: none;
}
html{
  scroll-behavior: smooth;
}
/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 0.4rem;
  width: 0.4rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// cursor pointer{
.pointer {
  cursor: pointer;
}

.fs-7 {
  font-size: 0.75rem;
}

.fs-8 {
  font-size: 0.675rem;
}

.tab-container-buttonbar-list .nav-link {
  font-size: 0.875rem;
}
/* stylelint-disable selector-max-id, declaration-no-important */
.active-view-icon {
  color: var(--element-ui-0-hover);
  border-color: var(--element-ui-0-hover) !important;
}
/* stylelint-enable selector-max-id, declaration-no-important */
.si-split-part-title > span {
  /* stylelint-disable-next-line declaration-no-important */
  transform: rotate(0deg) !important;
  color: #0cc;
}

// ag grid css

/* set the background color of many elements across the grid */
.ag-theme-alpine {
  --ag-background-color: var(--element-base-1);
  --ag-row-hover-color: var(--element-base-1-selected);
  --ag-font-family: var(--element-body-font-family);
  --ag-borders: solid 1px #f00;
  --ag-selected-row-background-color: #e8e8e3;
  --ag-odd-row-background-color: var(--element-base-1);
  --ag-header-background-color: var(--element-base-1);
  --ag-data-color: var(--bs-body-color);
  --ag-control-panel-background-color: var(--element-base-1);
  --ag-borders-input: 1px solid;
  --ag-input-border-color: #808080;
  --ag-selected-row-background-color: var(--element-base-1-selected);
}

.ag-root-wrapper {
  border-radius: 4px;
  box-shadow: 0 0 4px var(--element-box-shadow-color-1),0 4px 4px var(--element-box-shadow-color-2);
}

.ag-header.ag-header-allow-overflow .ag-header-row {
  border-block-end: 4px solid var(--element-ui-4);
}
/* stylelint-disable selector-max-id, declaration-no-important */
.ag-theme-alpine .ag-text-field-input {
  // background-color: var(--element-base-1) !important;
  color: var(--bs-body-color) !important;
}
/* stylelint-enable selector-max-id, declaration-no-important */

.ag-header-cell-label {
  padding: 0;
  display: flex;
  align-items: center;
  // border-block-end: 4px solid var(--element-ui-4);
  background-color: var(--element-base-1);
  color: var(--element-text-primary);
  block-size: 40px;
  vertical-align: baseline;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.143;
  user-select: none;
  user-select: none;
}

/* stylelint-disable selector-max-id, declaration-no-important */
.ag-header-icon {
  color: var(--element-ui-0) !important;
}
/* stylelint-enable selector-max-id, declaration-no-important */

.ag-select .ag-picker-field-wrapper {
  // background-color: var(--element-base-1);
  color: var(--bs-body-color);
}
/* stylelint-disable selector-max-id, declaration-no-important */
.ag-list-item.ag-select-list-item {
  color: var(--bs-body-color) !important;
}
/* stylelint-enable selector-max-id, declaration-no-important */

.ag-label{
  color: var(--element-text-primary);
}
.skeleton {
  width: 100%;
  height: 10px;
  animation: skeleton-loading 4s linear infinite alternate;
  border-radius: 4px;
  background-image: linear-gradient(to right, var(--element-base-0) 0%, var(--element-ui-4) 20%, var(--element-base-0) 40%, var(--element-base-0) 100%);
}
@keyframes skeleton-loading {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 100vw;
  }
}

.skeleton-line {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

//
.overflow-y-scroll{
  overflow-y: scroll;
}

.isFav-loading .loading svg{
  width: 22px;
  height: auto;
}

.btn {
 border-radius: 0.2rem;
}
